import * as React from "react"
import { Container, Box, Grid, Typography, makeStyles } from "@material-ui/core"
import Seo from "../../../components/seo"
import Menu from "../../../components/festival/menu"
import Band from "../../../components/festival/band"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from 'gatsby'

const highhi = <>De scheurende gitaren, atmosferische vocals en new wave-invloeden van High Hi brengen de jaren 80 binnen in de 21e eeuw. Sinds haar deelname aan Humo’s Rock Rally in 2016 bracht de band twee ambitieuze albums uit, waarin ze experimentalisme met catchy refreinen weten te verzoenen. High Hi produceert indiepoprock (en ook zoveel meer) en wordt beschreven met een meltingpot van invloeden: van The Pixies en Fleetwood Mac tot Vampire Weekend en Arcade Fire. Pop met échte power, meer hoeft er eigenlijk niet over gezegd te worden.</>

const highhiImage =  <StaticImage 
src="../../../images/festival/vrijdagavond/high-hi.png" 
alt="Highhi"
placeholder="blurred"
/> 

const sylvie = <>Sylvie Kreusch raast als een tornado door de huidige Belgische muziekwereld, en ook dat hoor je in haar muziek. U kent haar misschien nog van De Nieuwe Lichting en als de muze van Warhaus die een Belgian Music Award wist binnen te halen. De zangeres produceert vurige singles waarin haar ze sensuele, kwetsbare doch onverstoorbare stem de vrije loop laat. Soulvolle blazers, delicate violen of loeiende gitaren verlenen haar nummers een niet te ontkennen stoerheid. Sylvie Kreusch brengt het volledige plaatje en staat op het podium als een soort punk-overgoten Florence Welsh. Haar gevoel voor stijl en onmogelijk te vervalsen je-ne-sais-quoi werden geprezen door o.m. American Vogue, The New York Times, Dazed en Prada.<br/><br/><br/></>

const sylvieImage =  <StaticImage 
src="../../../images/festival/vrijdagavond/sylvie-kreusch.png" 
alt="Sylvie Kreusch"
placeholder="blurred"
/>  


const useStyles = makeStyles(theme => ({
  pijlRechts: {
      float: 'right',
  },
  pijlLinks: {
    float: 'left',
},
  title:{
    textAlign: "center"
  }
 }));

const VrijdagAvondPage = () => {
  const classes = useStyles();
  return (

    <Container maxWidth="lg">
      <Seo title="Tomatostories - Lineup vrijdagavond" />
      <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Menu/>
            </Grid>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h3" component="h1" color="primary">
              <Link to="/festival/lineup/vrijdagmiddag">
                  <StaticImage 
                      src="../../../images/pijl-links.png" 
                      alt="Vrijdagmiddag 20/08" 
                      placeholder="tracedSVG"
                      height={60}
                      className={classes.pijlLinks}
                      />
                 </Link>
                vrijdag 18/08 - namiddag
               
              </Typography>
              
            </Grid>
            <Band text={highhi} time="19u" title="High Hi" image={highhiImage} orientation="left"/>
            <Band text={sylvie} time="21u" title="Sylvie Kreusch" image={sylvieImage} orientation="right"/>
          </Grid>
        </Box>    
      </Container>

)}

export default VrijdagAvondPage
